.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(251, 240, 223);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Footer{
  position: fixed;
  bottom: 0;
  width: 100%;
   height: 8vmin;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #1F85DE;
  color: white;
  padding: .5vmin;
}
.Header {
  width: 100vw;
  height: 8vmin;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #1F85DE;
  color: white;
  padding: .5vmin;
}

.profileImage {
  width: 30vmin;
  height: 30vmin;
}

.ProjectList .card-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.ProjectList .accordion-summary {
  display: flex;
  flex-flow: row nowrap;
  gap: 2vmin;
  justify-content: space-between;
  align-items: center;
  width: 100%
}

.ProjectList .accordion-summary span {
  flex-basis: 100%;
  color: #767774;
  font-size: 1.5vm;
  font-weight: bold;
}

.sectionHeader {
  color: #1F85DE;
  font-size: '1.5vw';
}